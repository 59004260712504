import React from "react"
import Slider from "react-slick"

const ReferralLinks = ({ data }) => {
  const slider = React.useRef(null)
  const { referralLinks, referralSlider } = data

    var ReferralSlider = {
		dots: true,
		infinite: true,
		slidesToShow: 1,
    slidesToScroll: 1,
		responsive: [
		{
		  breakpoint: 767,
		  settings: {
		    slidesToShow: 1,
		    slidesToScroll: 1
		  }
		}
		]
    };
  
  return (
    <div className="col col-md-6 referral-link-col">
      {referralLinks.map(link => (
        <a href={link.url} key={link.id}>
          <div className="referral-link-box">
            <h5>
              {link.title}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20.243"
                height="13.501"
                viewBox="0 0 20.243 13.501"
              >
                <path
                  id="Icon_ionic-ios-arrow-round-forward"
                  data-name="Icon ionic-ios-arrow-round-forward"
                  d="M20.784,11.51a.919.919,0,0,0-.007,1.294l4.275,4.282H8.782a.914.914,0,0,0,0,1.828H25.045L20.77,23.2a.925.925,0,0,0,.007,1.294.91.91,0,0,0,1.287-.007l5.794-5.836h0a1.026,1.026,0,0,0,.19-.288.872.872,0,0,0,.07-.352.916.916,0,0,0-.26-.64l-5.794-5.836A.9.9,0,0,0,20.784,11.51Z"
                  transform="translate(-7.875 -11.252)"
                  fill="#ff0245"
                />
              </svg>
            </h5>
            <p>{link.content.content}</p>
          </div>
        </a>
      ))}

      <div className="referral-link-slider">
        <Slider {...ReferralSlider} ref={slider}>
        {referralSlider.map(slider => (
          <div key={slider.id}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="69.564"
              height="49.689"
              viewBox="0 0 69.564 49.689"
            >
              <path
                id="Icon_material-format-quote"
                data-name="Icon material-format-quote"
                d="M12.469,60.189H27.376l9.938-19.876V10.5H7.5V40.313H22.407Zm39.751,0H67.127l9.938-19.876V10.5H47.251V40.313H62.158Z"
                transform="translate(-7.5 -10.5)"
                fill="#e2ebf8"
                opacity="0.2"
              />
            </svg>
            <p>{slider.testimonial.testimonial}</p>
            <h6>{slider.client}</h6>
          </div>
        ))}
        </Slider>
      </div>
    </div>
  )
}

export default ReferralLinks
