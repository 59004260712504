import React, { useState } from "react"
import Select from "react-select"
import ReferralLinks from "../shared/referral_links"
import ReCAPTCHA from "react-google-recaptcha"

const customStyles = {
  control: (base, state) => ({
    ...base,
    // background: "transparent",
    border: "2px solid #fff",
    color: "#000",
    // match with the menu
    borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "#ff0245" : "#ff0245",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#ff0245" : "#ff0245",
      boxShadow: state.isFocused ? null : null,
      color: state.isFocused ? "brown" : "grey",
    },
  }),
  menu: (base, state) => ({
    ...base,
    // override border radius to match the box
    borderRadius: 0,
    border: "1px solid #ff0245",
    // kill the gap
    marginTop: 0,
  }),
  menuList: (base, state) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
    color: "#262b4a",
    color: state.isFocused ? "orange" : "#ff0245",
  }),
}

const ReferralForm = ({ data }) => {
  const [capVal, setCapVal] = useState()
  const [values, setValues] = useState({
    doc: "",
    hospital: "",
    phone: "",
    fax: "",
    email: "",
    isPatient: "",
    serviceRequested: "",
    otherInfo: "",
    fName: "",
    lName: "",
    ownerPhone: "",
    ownerEmail: "",
    breed: "",
    gender: "",
    nOrS: "",
    date: "",
    color: "",
    vaccinationStatus: "",
    history: "",
    currentProblems: "",
    diagnosis: "",
    medication: "",
    preferences: "",
    comments: "",
  })
  const [error, setError] = useState("")
  const [success, setSuccess] = useState(false)

  const [capErr, setCapErr] = useState("")
  const [docErr, setDocErr] = useState("")
  const [hospitalErr, setHospitalErr] = useState("")
  const [phoneErr, setPhoneErr] = useState("")
  const [faxErr, setFaxErr] = useState("")
  const [emailErr, setEmailErr] = useState("")
  const [isPatientErr, setIsPatientErr] = useState("")
  const [serviceRequestedErr, setServiceRequestedErr] = useState("")
  const [vaccinationStatusErr, setVaccinationStatusErr] = useState("")
  const [ownerEmailErr, setOwnerEmailErr] = useState("")
  const [ownerPhoneErr, setOwnerPhoneErr] = useState("")
  const [breedErr, setBreedErr] = useState("")
  const [genderErr, setGenderErr] = useState("")
  const [nOrSErr, setNOrSErr] = useState("")
  const [dateErr, setDateErr] = useState("")
  const [colorErr, setColorErr] = useState("")
  const [historyErr, setHistoryErr] = useState("")
  const [currentProblemsErr, setCurrentProblemsErr] = useState("")
  const [diagnosisErr, setDiagnosisErr] = useState("")

  const {
    doc,
    hospital,
    phone,
    fax,
    email,
    isPatient,
    serviceRequested,
    otherInfo,
    fName,
    lName,
    ownerEmail,
    ownerPhone,
    breed,
    gender,
    nOrS,
    date,
    color,
    vaccinationStatus,
    history,
    currentProblems,
    diagnosis,
    medication,
    preferences,
    comments,
  } = values

  const handleChange = e =>
    setValues({ ...values, [e.target.name]: e.target.value })

  const onChangeIsPatient = selectedOption =>
    setValues({ ...values, isPatient: selectedOption })
  const onChangeServiceRequested = selectedOption =>
    setValues({ ...values, serviceRequested: selectedOption })
  const onChangeOtherInfo = e =>
    setValues({ ...values, otherInfo: e.target.value })
  const onChangeMedicalRecord = e =>
    setValues({ ...values, medicalRecord: e.target.value })
  const onChangeGender = selectedOption =>
    setValues({ ...values, gender: selectedOption })
  const onChangeNeuteredOrSpayed = selectedOption =>
    setValues({ ...values, nOrS: selectedOption })
  const onChangeVaccinationStatus = selectedOption =>
    setValues({ ...values, vaccinationStatus: selectedOption })

  const isPatientOptions = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
    { value: "Not Sure", label: "Not Sure" },
  ]

  const vaccinationStatusOptions = [
    { value: "Current", label: "Current" },
    { value: "Pastdue", label: "Pastdue" },
    { value: "Unsure", label: "Unsure" },
  ]

  const serviceRequestedOptions = [
    { value: "Emergency & Critical Care", label: "Emergency & Critical Care" },
    { value: "Surgery", label: "Surgery" },
    { value: "New Client/Patient Visit", label: "New Client/Patient Visit" },
    { value: "Sick Pet Exam", label: "Sick Pet Exam" },
    { value: "Annual Pet Exam", label: "Annual Pet Exam" },
    {
      value: "Other (Explain Service details in textbox below)",
      label: "Other (Explain Service details in textbox below)",
    },
  ]

  const genderOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ]
  const nOrSOptions = [
    { value: "Spayed", label: "Spayed" },
    { value: "Not Yet", label: "Not Yet" },
  ]

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }

  const submitForm = async () => {
    setError("")
    setSuccess(false)

    setCapErr("")
    setDocErr("")
    setHospitalErr("")
    setPhoneErr("")
    setFaxErr("")
    setEmailErr("")
    setIsPatientErr("")
    setServiceRequestedErr("")
    setVaccinationStatusErr("")
    setOwnerPhoneErr("")
    setBreedErr("")
    setDateErr("")
    setColorErr("")
    setOwnerEmailErr("")
    setGenderErr("")
    setNOrSErr("")
    setHistoryErr("")
    setCurrentProblemsErr("")
    setDiagnosisErr("")

    if (!capVal) {
      setCapErr("Are you a robot?")
    }
    if (!doc) {
      setDocErr("Doctor Name is required")
    }
    if (!hospital) {
      setHospitalErr("Hospital Name is required")
    }
    if (!vaccinationStatus.value) {
      setVaccinationStatusErr("Vaccination Status is required")
    }
    if (!phone) {
      setPhoneErr("Phone Number is required")
    }
    if (!fax) {
      setFaxErr("Fax is required")
    }
    if (!email) {
      setEmailErr("Email is required")
    }
    if (!isPatient.value) {
      setIsPatientErr("Patient Info is required")
    }
    if (!serviceRequested.value) {
      setServiceRequestedErr("Service Requested is required")
    }

    if (!ownerPhone) {
      setOwnerPhoneErr("Phone is required")
    }
    if (!breed) {
      setBreedErr("Breed is required")
    }
    if (!date) {
      setDateErr("Date is required")
    }
    if (!color) {
      setColorErr("Color is required")
    }
    if (!ownerEmail) {
      setOwnerEmailErr("Email is required")
    }
    if (!gender) {
      setGenderErr("Gender is required")
    }
    if (!nOrS) {
      setNOrSErr("Neutered / Spayed Detail is required")
    }
    if (!history) {
      setHistoryErr("Past Medical History is required")
    }
    if (!currentProblems) {
      setCurrentProblemsErr("Current Problems Detail is required")
    }
    if (!diagnosis) {
      setDiagnosisErr("Diagnosis Given is required")
    }

    if (!validateEmail(email)) {
      setEmailErr("Please provide a valid email")
    }
    if (
      !capVal ||
      !doc ||
      !hospital ||
      !phone ||
      !fax ||
      !email ||
      !isPatient ||
      !serviceRequested ||
      !ownerPhone ||
      !ownerEmail ||
      !breed ||
      !gender ||
      !nOrS ||
      !date ||
      !color ||
      !vaccinationStatus ||
      !history ||
      !currentProblems ||
      !diagnosis
    ) {
      return
    }
    try {
      const res = await fetch("/.netlify/functions/vetReferral", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      })
      if (res.ok) {
        setSuccess(true)
      }
    } catch (err) {
      console.log(err)
      setError("Something went wrong")
    }
  }
  return (
    <section className="referral-form-sec contact-form-sec">
      <div className="container">
        <div className="row">
          <div className="col">
            <h2>Referring Veterinarian</h2>
          </div>
          <div className="col">
            <div className="row">
              <div className="col col-md-6 referral-form-sec-inner">
                <label>
                  <input
                    type="text"
                    name="doc"
                    placeholder="Doctor Name*"
                    value={doc}
                    onChange={handleChange}
                  />
                  {docErr && <p>{docErr}</p>}
                </label>
                <label>
                  <input
                    type="text"
                    name="hospital"
                    placeholder="Hospital Name*"
                    value={hospital}
                    onChange={handleChange}
                  />
                  {hospitalErr && <p>{hospitalErr}</p>}
                </label>
                <label>
                  <input
                    type="phone"
                    name="phone"
                    placeholder="Phone Number*"
                    value={phone}
                    onChange={handleChange}
                  />
                  {phoneErr && <p>{phoneErr}</p>}
                </label>
                <label>
                  <input
                    type="text"
                    name="fax"
                    placeholder="Fax*"
                    value={fax}
                    onChange={handleChange}
                  />
                  {faxErr && <p>{faxErr}</p>}
                </label>
                <label>
                  <input
                    type="text"
                    name="email"
                    placeholder="Email*"
                    value={email}
                    onChange={handleChange}
                  />
                  {emailErr && <p>{emailErr}</p>}
                </label>
                <label>
                  <Select
                    options={isPatientOptions}
                    className={`customDropDown`}
                    classNamePrefix={`customDropDown`}
                    required
                    styles={customStyles}
                    placeholder={`Is Your Pet Currently A Patient?*`}
                    name="isPatient"
                    value={isPatient}
                    onChange={onChangeIsPatient}
                  />
                  {isPatientErr && <p>{isPatientErr}</p>}
                </label>
                <label>
                  <Select
                    options={serviceRequestedOptions}
                    className={`customDropDown`}
                    classNamePrefix={`customDropDown`}
                    required
                    styles={customStyles}
                    placeholder={`Service Requested*`}
                    name="serviceRequested"
                    value={serviceRequested}
                    onChange={onChangeServiceRequested}
                  />
                  {serviceRequestedErr && <p>{serviceRequestedErr}</p>}
                </label>
                <div className="referral-form-group">
                  <h6>Other Information Sent</h6>
                  <div className="referral-form-fields">
                    <label>
                      <input
                        type="radio"
                        name="otherInfo"
                        value="yes"
                        checked={otherInfo === "yes"}
                        onChange={onChangeOtherInfo}
                      />
                      <span></span>
                      Yes
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="otherInfo"
                        value="no"
                        checked={otherInfo === "no"}
                        onChange={onChangeOtherInfo}
                      />
                      <span></span>
                      No
                    </label>
                  </div>
                </div>
              </div>
              <ReferralLinks data={data} />
            </div>
          </div>
        </div>
      </div>
      <div className="container referral-form-con">
        <div className="row">
          <div className="col">
            <h2>Patient Information</h2>
          </div>
          <div className="col">
            <div className="row">
              <div className="col col-md-6 referral-form-sec-inner">
                <label>
                  <input
                    type="text"
                    name="fName"
                    placeholder="First Name (Owner)"
                    value={fName}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  <input
                    type="text"
                    name="ownerPhone"
                    placeholder="Phone Number*"
                    value={ownerPhone}
                    onChange={handleChange}
                  />
                  {ownerPhoneErr && <p>{ownerPhoneErr}</p>}
                </label>
                <label>
                  <input
                    type="text"
                    name="breed"
                    placeholder="Breed"
                    value={breed}
                    onChange={handleChange}
                  />
                  {breedErr && <p>{breedErr}</p>}
                </label>
                <div className="referral-form-half">
                  <label>
                    <input
                      type="date"
                      name="date"
                      value={date}
                      onChange={handleChange}
                    />
                    {dateErr && <p>{dateErr}</p>}
                  </label>
                  <label>
                    <input
                      type="text"
                      name="color"
                      placeholder="Color:*"
                      value={color}
                      onChange={handleChange}
                    />
                    {colorErr && <p>{colorErr}</p>}
                  </label>
                </div>
              </div>
              <div className="col col-md-6 referral-form-sec-inner referral-form-sec-inner1">
                <label>
                  <input
                    type="text"
                    name="lName"
                    placeholder="Last Name (Owner)"
                    value={lName}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  <input
                    type="text"
                    name="ownerEmail"
                    placeholder="Email*"
                    value={ownerEmail}
                    onChange={handleChange}
                  />
                  {ownerEmailErr && <p>{ownerEmailErr}</p>}
                </label>
                <div className="referral-form-half">
                  <label>
                    <Select
                      options={genderOptions}
                      className={`customDropDown zIndexFix`}
                      classNamePrefix={`customDropDown`}
                      required
                      styles={customStyles}
                      placeholder={`Gender: *`}
                      name="gender"
                      value={gender}
                      onChange={onChangeGender}
                    />
                    {genderErr && <p>{genderErr}</p>}
                  </label>
                  <label>
                    <Select
                      options={nOrSOptions}
                      className={`customDropDown zIndexFix`}
                      classNamePrefix={`customDropDown`}
                      required
                      styles={customStyles}
                      placeholder={`Neutered / Spayed?:*`}
                      name="nOrS"
                      value={nOrS}
                      onChange={onChangeNeuteredOrSpayed}
                    />
                    {nOrSErr && <p>{nOrSErr}</p>}
                  </label>
                </div>
                <label>
                  <Select
                    options={vaccinationStatusOptions}
                    className={`customDropDown`}
                    classNamePrefix={`customDropDown`}
                    required
                    styles={customStyles}
                    placeholder={`Vaccination Status?: *`}
                    name="vaccinationStatus"
                    value={vaccinationStatus}
                    onChange={onChangeVaccinationStatus}
                  />
                  {vaccinationStatusErr && <p>{vaccinationStatusErr}</p>}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container referral-form-con referral-form-con1">
        <div className="row">
          <div className="col">
            <h2>Medical Details</h2>
          </div>
          <div className="col">
            <div className="row">
              <div className="col referral-form-sec-inner">
                <label>
                  <textarea
                    placeholder="Significant Past Medical History/Problems*"
                    name="history"
                    value={history}
                    onChange={handleChange}
                  ></textarea>
                  {historyErr && <p>{historyErr}</p>}
                </label>
                <label>
                  <textarea
                    placeholder="Current Problems (Please Indicate/Describe Chief Complaint/Onset/Progression/Treatments)*"
                    name="currentProblems"
                    value={currentProblems}
                    onChange={handleChange}
                  ></textarea>
                  {currentProblemsErr && <p>{currentProblemsErr}</p>}
                </label>
                <label>
                  <input
                    type="text"
                    name="diagnosis"
                    placeholder="Tentative Diagnosis Given To Client*"
                    value={diagnosis}
                    onChange={handleChange}
                  />
                  {diagnosisErr && <p>{diagnosisErr}</p>}
                </label>
                <label>
                  <input
                    type="text"
                    name="medication"
                    placeholder="Medication History"
                    value={medication}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  <input
                    type="text"
                    name="preferences"
                    placeholder="If You Have Additional Preferences, Please Let Us Know:"
                    value={preferences}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  <input
                    type="text"
                    name="comments"
                    placeholder="Additional Comments"
                    value={comments}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  <ReCAPTCHA
                    sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
                    onChange={val => setCapVal(val)}
                  />
                  {capErr && <p>{capErr}</p>}
                </label>
                <div className="referral-form-group checkboxFormValid">
                  <ul className="justify-content-center d-flex">
                    <li>
                      <label>
                        <input type="checkbox" name="radio1" />
                        <span></span>
                        <p>
                          By submitting this form, I hereby authorize Canton
                          Animal Hospital to render medical care for my pet(s)
                          as deemed necessary by the veterinarian. I understand
                          that no guarantee can be given to the outcome of
                          treatments and take it as my responsibility to
                          appropriately comprehend any risks involved. I agree
                          to pay for the cost of all services to which I consent
                          to by written or verbal estimate. I understand that a
                          deposit is required before diagnostics and treatments
                          can be initiated and that payment in full is required
                          prior to patient discharge from Canton Animal
                          Hospital.
                        </p>
                      </label>
                    </li>
                  </ul>
                </div>
                <div className="referral-link-btn text-center">
                  <button type="button" className="d-btn" onClick={submitForm}>
                    Submit
                  </button>
                  {error && <p>{error}</p>}
                  {success && (
                    <p>
                      Thank you for contacting us! We'll be in touch with you
                      soon.
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ReferralForm
